.app-container {
  margin-top: 48px;
  padding: 32px;
}
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.button-home-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 32px;
}

.button-home-container {
  border-radius: 8px;

  padding: 32px;

  background-color: #fff;

  box-shadow: 0 2px 7px 0 #153d780d;
}

.selector-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  background-color: #fff;
  border: 2px solid #580a73;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 rgb(192 192 192 / 12%);
  cursor: pointer;
  flex: 1 1;
  /* font-family: "Noto Sans"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  min-width: 220px;
  padding: 20px 40px 20px 40px;
  position: relative;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #232f3e;
  text-decoration: none;
}

.selector-button:hover {
  background: #580a73;
  color: #fff;
}
.selector-button:hover svg path {
  fill: #fff;
}

.selector-button:hover::after {
  bottom: 0;
  content: url('data:image/svg+xml;charset=utf-8,<svg width="10" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.05 17.55 0 16.5l7.725-7.725L0 1.05 1.05 0l8.775 8.775L1.05 17.55Z" fill="%23fff"/></svg>');
  height: 20px;
  position: absolute;
  right: 20px;
  top: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* ////////// */

/* ////////// */

.btn-group-botm {
  padding-top: 20px;

  display: flex;

  align-items: center;

  gap: 10px;
}

.clear-form-btn {
  background: none;

  border: none;

  cursor: pointer;
}
