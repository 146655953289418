.authentication-container {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 48px;
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: end;
}
.actions .actions-enabled,.actions .actions-disabled{
    text-align: center!important;
    border-radius: 8px!important;
}
.actions .actions-enabled{
    background: #580A73!important;
    color:#fff;
}
.auth-header >*{
    text-align: center;
    width: 100%;
    align-items: center!important;
    justify-content: center!important;
}