.range-and-loader {
    display: flex;
    justify-content: left;
    align-items: center;
}
.range-and-loader .range-loader {
    width: 26px !important;
    height: 26px !important;
}
.range-disable {
    opacity: 0.5 !important;
    cursor: not-allowed;
}