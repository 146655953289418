#root{
  overflow: hidden!important;
  --color-text-accent-s0earc:#8E1BB5!important;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.main-app-container{
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(0px + 100vh);
}
.navigation-container {
  margin-top: -16px;
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 20px;
}

.navigation-container header{
  background: #fff!important;
  border-bottom: 1.5px solid #8E1BB5;
  
}

.navigation-container a{
  
  color:#000!important;
  font-weight: 500!important;
}

.authentication-container{
  padding: 32px;
  background: #fff;
  border-radius: 8px;
}

.flex-align-right {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
